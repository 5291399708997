import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Button, Col, Container, Row } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import AddressAndTransport from "../components/AddressAndTransport";
import { parseNestedHTML } from "../Utils";
import SafeHtmlParser from "../components/safe-html-parser";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const AdultNightActivities = () => {
	const data = useStaticQuery(graphql`
		query {
			wpEvent {
				adultEvent {
					showThirdSection
					address {
						btnText
						location {
							lat
							lng
						}
						title
					}
					heroSection {
						btnText
						subheading
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					pricing {
						btnText
						subheading
						title
					}
					refreshmentsAvailable {
						body
						subheading
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					services {
						body
						title
					}

					thirdSection {
						body
						subheading
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					timeTrials {
						body
						subheading
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					unleashYourInnerNinja {
						body
						btntext
						subheading
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					vehicleStatus {
						body
						icon {
							node {
								sourceUrl
								id
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						title
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const AdultEventPage = data.wpEvent.adultEvent;
	const seoFields = data.wpEvent.seoFields;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Adult Night Activities",
				item: {
					url: `${siteUrl}/adult-night-activities`,
					id: `${siteUrl}/adult-night-activities`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/adult-night-activities`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={AdultEventPage.heroSection.title}
												/>
											</div>
											<p className="text-white py-3 fs-4">
												{AdultEventPage.heroSection.subheading}
											</p>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
												className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
											>
												{AdultEventPage.heroSection.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>

						<div className="slider-container">
							<Slider {...settings}>
								{AdultEventPage.heroSection.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>

				<section className="py-5 py-lg-7">
					<Container>
						<Row className="g-5 align-items-center">
							<Col lg={6}>
								<h2 className="display-5 pb-4 text-uppercase">
									{AdultEventPage.unleashYourInnerNinja.title}
								</h2>
								<p className="text-white">
									{AdultEventPage.unleashYourInnerNinja.subheading}
								</p>
								<div>
									<SafeHtmlParser
										htmlContent={AdultEventPage.unleashYourInnerNinja.body}
									/>
								</div>
								<Button
									target="_blank"
									rel="noreferrer"
									href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
									className="text-white text-uppercase w-100 w-md-auto mt-4 fs-5 px-4 py-2"
								>
									{AdultEventPage.unleashYourInnerNinja.btntext}
								</Button>
							</Col>
							<Col lg={{ span: 6, order: "last" }}>
								<div className="slider-container">
									<Slider {...settings}>
										{AdultEventPage.unleashYourInnerNinja.slider.map(
											(slider, i) => (
												<div key={i}>
													<GatsbyImage
														image={
															slider.image?.node.localFile?.childImageSharp
																?.gatsbyImageData
														}
														alt={slider.image?.node?.altText}
													/>
												</div>
											)
										)}
									</Slider>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Row className="align-items-center gx-0">
						<Col lg={6}>
							<div
								style={{
									position: "relative",
									display: "inline-block", // Or 'block' depending on your layout
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										background:
											"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
										pointerEvents: "none", // Allows interaction with the GatsbyImage
										zIndex: 1, // Ensures the overlay is above the image
									}}
								></div>
								<GatsbyImage
									image={
										AdultEventPage.timeTrials.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									className="w-100"
									style={{
										objectFit: "cover",
										width: "100%",
									}}
									alt={AdultEventPage.timeTrials.image?.node?.altText}
								/>
							</div>
						</Col>

						<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
							<h3 className="display-5  text-uppercase text-white ">
								{AdultEventPage.timeTrials.title}
							</h3>
							<div className="">
								<SafeHtmlParser htmlContent={AdultEventPage.timeTrials.body} />
							</div>
							<p className="text-white">
								{AdultEventPage.timeTrials.subheading}
							</p>
						</Col>
					</Row>
					<Row className="align-items-center gx-0">
						<Col lg={{ span: 6, order: "last" }}>
							<div
								style={{
									position: "relative",
									display: "inline-block", // Or 'block' depending on your layout
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										background:
											"linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
										pointerEvents: "none", // Allows interaction with the GatsbyImage
										zIndex: 1, // Ensures the overlay is above the image
									}}
								></div>
								<GatsbyImage
									image={
										AdultEventPage.refreshmentsAvailable.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									className="w-100"
									style={{
										objectFit: "cover",
									}}
									alt={
										AdultEventPage.refreshmentsAvailable.image?.node?.altText
									}
								/>
							</div>
						</Col>
						<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
							<h3 className="display-5  text-uppercase text-white ">
								{AdultEventPage.refreshmentsAvailable.title}
							</h3>
							<div className="">
								<SafeHtmlParser
									htmlContent={AdultEventPage.refreshmentsAvailable.body}
								/>
							</div>
							<p className="text-white">
								{AdultEventPage.refreshmentsAvailable.subheading}
							</p>
						</Col>
					</Row>
					{AdultEventPage.showThirdSection === true && (
						<Row className="align-items-center gx-0">
							<Col lg={6}>
								<div
									style={{
										position: "relative",
										display: "inline-block", // Or 'block' depending on your layout
									}}
								>
									<div
										style={{
											position: "absolute",
											top: 0,
											right: 0,
											bottom: 0,
											left: 0,
											background:
												"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
											pointerEvents: "none", // Allows interaction with the GatsbyImage
											zIndex: 1, // Ensures the overlay is above the image
										}}
									></div>
									<GatsbyImage
										image={
											AdultEventPage.thirdSection.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										className="w-100"
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={AdultEventPage.thirdSection.image?.node?.altText}
									/>
								</div>
							</Col>

							<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
								<h3 className="display-5  text-uppercase text-white ">
									{AdultEventPage.thirdSection.title}
								</h3>
								<div className="">
									<SafeHtmlParser
										htmlContent={AdultEventPage.thirdSection.body}
									/>
								</div>
								<p className="text-white">
									{AdultEventPage.thirdSection.subheading}
								</p>
							</Col>
						</Row>
					)}
				</section>
				<section className="pb-5 ">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} className="text-center">
								<h2 className="display-5  text-uppercase">
									{AdultEventPage.pricing.title}
								</h2>

								<p className="fs-5">{AdultEventPage.pricing.subheading}</p>
								<div className="d-flex justify-content-center">
									<Button
										as={Link}
										to="/discounts"
										className="text-white text-uppercase w-100 w-md-auto mt-3 fs-5 px-4 py-2"
									>
										{AdultEventPage.pricing.btnText}
									</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="pb-5 pb-lg-7">
					<Container className="text-center">
						<Row className="justify-content-center g-4">
							{AdultEventPage.services.map(({ title, body }, i) => (
								<Col lg={4} md={6} key={i}>
									<div
										style={{ backgroundColor: "#181819", borderRadius: 12 }}
										className="p-4 text-center h-100"
									>
										<h2 className="fs-3">{title}</h2>
										<p className="text-white">{body}</p>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<AddressAndTransport
						address={AdultEventPage.address}
						vehiclesStatus={AdultEventPage.vehicleStatus}
					/>
				</section>
			</Layout>
		</div>
	);
};

export default AdultNightActivities;
